body {
  margin: 0;
  padding: 0 0 0 0;
  font-family: sans-serif;

  background-image: url(./images/7daysinn.jpeg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* overflow: hidden; */
}

.title {
  font-family: "URW Chancery L", cursive;
  font-size: 100px;
  margin-top: 0;
  margin-bottom: 3px;
  text-align: center;
  background-image: linear-gradient(
    to left,
    /* violet,
    indigo,
    blue,
    green,
    yellow,
    orange, */ red,
    yellow
  );
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.nav-link {
  text-align: right;
}

.dishes {
  max-width: 50%;
  height: auto;
  border-radius: 50%;
}

.thumbnail:hover {
  position: relative;
  top: -25px;
  left: -35px;
  width: 500px;
  height: auto;
  display: block;
  z-index: 999;
}

.clickable {
  cursor: pointer;
}
/* 
.navbar {
  position: absolute;
} */
